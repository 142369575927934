import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useSearchParams } from "react-router-dom";
import swal from 'sweetalert';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '365px',
        margin: '0 auto'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: '#D8DF1B',
        '&:hover': {
            backgroundColor: '#B5C113'
        }
    },
    helperText: {
        color: 'red',
    },
}));

async function login(credentials) {
    return fetch(`${process.env.REACT_APP_AUTH_URL}/login/sso`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: credentials.email,
            password: credentials.password
        })
    })
        .then(data => data.json())
}

/* async function getVenues(access_token) {
    return fetch(`${process.env.REACT_APP_API_URL}/venues?mode=min&limit=20&offset=0&sortby[name]=ASC`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then(data => data.json())
} */

export default function SignIn() {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [showSelector, setShowSelector] = useState(false);
    const [searchParams] = useSearchParams();
    const callbackUrl = searchParams.get("mz_callback");

    const handleSubmit = async e => {
        e.preventDefault();
        if (!email || !password) {
            swal("Error", "Email y contraseña son obligatorios", "error");
            return;
        }
        setLoading(true);
        const response = await login({ email, password });
        if ('access_token' in response) {
            window.location.href = `${callbackUrl}?venueId=${response['access_token']}`;
        } else if ('vendors' in response) {
            setVendors(response.vendors);
            setShowSelector(true);
            setLoading(false);
        } else {
            setLoading(false);
            swal("Error", response.message, "error");
        }
    };

    const handleSubmitVendor = async e => {
        e.preventDefault();
        if (!selectedVendor) {
            swal("Error", "Debe seleccionar un restaurante", "error");
            return;
        }
        setLoading(true);
        if ('access_token' in selectedVendor) {
            window.location.href = `${callbackUrl}?venueId=${selectedVendor.access_token}`;
        } else {
            setLoading(false);
            swal("Failed", "No tiene el token de acceso", "error");
        }
    };

    const handleVendorChange = (event, newValue) => {
        setSelectedVendor(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <Grid container justifyContent="center" className={classes.root}>
            <CssBaseline />
            <Grid item md={6}>
                {callbackUrl ? (
                    <div className={classes.paper}>
                        <img src="/brand.png" height="50" alt="Precompro SSO" />
                        {showSelector ? (
                            <div style={{ marginTop: '16px' }}>
                                <Autocomplete
                                    value={selectedVendor}
                                    onChange={handleVendorChange}
                                    options={vendors}
                                    getOptionLabel={(option) =>
                                        option.sede ? `${option.displayName} (${option.sede})` : option.displayName
                                    }
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Seleccione restaurante"
                                            variant="outlined"
                                            style={{ width: '360px' }}
                                        />
                                    )}
                                    filterOptions={(options, state) => 
                                        options.filter(option => 
                                            option.displayName.toLowerCase().includes(state.inputValue.toLowerCase())
                                        )
                                    }
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmitVendor}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Aceptar'}
                                </Button>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    onChange={e => setEmail(e.target.value)}
                                    error={!email && false}
                                    helperText={!email ? '* Email obligatorio' : ''}
                                    FormHelperTextProps={{
                                        classes: { root: classes.helperText }
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    error={!password && false}
                                    helperText={!password ? '* Contraseña obligatoria' : ''}
                                    FormHelperTextProps={{
                                        classes: { root: classes.helperText }
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Entrar'}
                                </Button>
                            </form>
                        )}
                    </div>
                ) : (
                    <h3>A callback url is missing</h3>
                )}
            </Grid>
        </Grid>
    );
}
