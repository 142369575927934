import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SignIn from './SignIn';


function App() {
    return (
        <div className="wrapper">
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<SignIn />}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
